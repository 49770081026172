import { useState, useCallback } from "react";

export const useApi = (apiFunc) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (...args) => {
      setErrorMessage("");
      setLoading(true);
      try {
        const response = await apiFunc(...args);
        setLoading(false);

        if (!response.ok) {
          setErrorMessage(response.data.because);
          setLoading(false);
          return setError(true);
        }

        setError(false);
        setData(response.data.with);
        // console.log(response.data.with, response.data, "res data");
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(true);
      }
    },
    [apiFunc]
  );

  return { data, error, errorMessage, loading, request };
};
