export const darkestGray = "#29292c";
export const darkGray = "#787878";
export const lightGray = "rgba(60, 67, 97, 0.2)";
export const lightWarmGray = "#f5f5f5";
export const rfRed = "#cc0000";
export const white = "#ffffff";

export const facebookBlue = "#3b5998";
export const googleBlue = "#4c8bf5";

export const baseText = darkGray;
export const darkText = "#31393C";
export const sectionBackground = lightWarmGray;
export const background = lightWarmGray;
export const selected = rfRed;
export const unselected = lightGray;

export const primary = darkGray;
export const secondary = lightGray;
export const tertiary = lightGray;

export const transparentPrimary = "ff pink";

export const errorText = "red";

export const purple = "#414288";
export const labelGray = "#C0C5CA";
export const black = "#31393C";
export const blue = "#568CEB";
export const springGreen = "#16B4A4";
export const springGreenTransparent = "#8AD9D1";

export const lightBlue = "#E4E8F5";
export const verticalHrColor = "#DCDEDF";
