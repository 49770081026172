import React from "react";
import {
  View,
  Image,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Colors } from "../styles";
import avatarDefault from "../assets/avatar.png";
import { StarRatingIndicator } from "./StarRatingIndicator";
import downloadIos from "../assets/downloadIos.png";
import downloadAndroid from "../assets/downloadAndroid.png";
import dayConversion from "../utils/dayConversion";
import timeConversion from "../utils/timeConversion";
import { getLevel } from "../utils/getLevel";

const Body = ({ listing, isBigScreen, allPassions, loadingPassions }) => {
  const {
    description,
    locations,
    availability,
    supplies,
    images,
    instructor,
    passionId: listingPassionId,
    avgRatingForPassion,
    reviewCountForPassion,
  } = listing;

  const {
    avatarThumb,
    passions: teacherPassions,
    bio,
    firstName,
    lastName,
    homeCity,
  } = instructor[0];

  const renderAboutListing = () => {
    return (
      <View
        style={!isBigScreen ? styles.aboutSectionSmall : styles.aboutSectionBig}
      >
        <Text style={styles.aboutHeader1}>About Listing:</Text>
        <Text style={styles.smallText}>{description}</Text>
        <Text style={styles.aboutHeader2}>Location:</Text>
        {locations.map((loc, index) => {
          return (
            <Text style={styles.smallText} key={`location${index}`}>
              {loc.type === "online"
                ? "-Online"
                : loc.type === "studentsHome"
                ? `-At the student's home, within a ${
                    loc.range || 15
                  } mile radius of ${homeCity}.`
                : loc.type === "myStudio"
                ? "-My home or studio (address will be provided at booking)"
                : loc.type === "publicSpace"
                ? `-At a public space, within a ${
                    loc.range || 15
                  } mile radius of ${homeCity}.`
                : null}
            </Text>
          );
        })}
        <Text style={styles.aboutHeader2}>Availability:</Text>
        {availability.map((avail, index) => {
          return (
            <Text style={styles.smallText} key={`availability${index}`}>
              {dayConversion(avail.dayOfWeek)} {timeConversion(avail.startTime)}
              {" - "}
              {timeConversion(avail.endTime)}
            </Text>
          );
        })}
        {supplies.length > 0 && (
          <>
            <Text style={styles.aboutHeader2}>Supplies:</Text>
            <Text style={styles.smallText}>{supplies}</Text>
          </>
        )}
        {images.length > 0 && (
          <>
            <Text style={styles.aboutHeader2}>Photos:</Text>
            <View
              style={{
                flexDirection: "row",
                overflow: "scroll",
              }}
            >
              {images.length > 0 &&
                images.map((item, index) => (
                  <Image
                    key={`image${index}`}
                    resizeMethod="scale"
                    resizeMode="cover"
                    style={{
                      height: 200,
                      width: 220,
                      borderRadius: 30,
                      marginVertical: 20,
                      marginRight: 10,
                    }}
                    source={{
                      uri: item.thumbUrl,
                    }}
                  />
                ))}
            </View>
          </>
        )}
      </View>
    );
  };

  const renderAboutTeacher = () => {
    let instructorLevel = getLevel(
      allPassions,
      teacherPassions,
      listingPassionId
    );

    return (
      <View style={!isBigScreen ? styles.sideBarSmall : styles.sideBarBig}>
        <View style={styles.instructorRow}>
          <Image
            source={avatarThumb ? avatarThumb : avatarDefault}
            resizeMode="contain"
            style={styles.avatar}
          />
          <Text style={styles.aboutHeader2}>
            {`${firstName} ${lastName}`}
            {"\n"}
            {loadingPassions ? <ActivityIndicator /> : `${instructorLevel}`}
          </Text>
        </View>

        <StarRatingIndicator
          value={avgRatingForPassion}
          reviews={reviewCountForPassion}
        />

        <View>
          <Text style={styles.aboutHeader1}>About Me:</Text>
          <Text style={styles.smallText}>{bio}</Text>
        </View>
        {/* <TouchableOpacity style={styles.button}>
          <Text style={styles.buttonText}>Download skillin'</Text>
        </TouchableOpacity> */}
        <TouchableOpacity
          onPress={() =>
            window.open(
              "https://apps.apple.com/us/app/skillin/id1559802810",
              "_blank"
            )
          }
        >
          <Image style={styles.downloadImage} source={downloadIos} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.skillco.skillin",
              "_blank"
            )
          }
        >
          <Image style={styles.downloadImage} source={downloadAndroid} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: isBigScreen ? "row" : "column" }}>
      {renderAboutListing()}
      {isBigScreen && <View style={styles.verticalHr} />}
      {renderAboutTeacher()}
    </View>
  );
};

const styles = StyleSheet.create({
  aboutSectionBig: {
    marginTop: 40,
    marginHorizontal: 20,
    width: "60%",
  },
  aboutSectionSmall: {
    marginHorizontal: 10,
  },
  aboutHeader1: {
    color: "black",
    fontFamily: "Avenir-Book",
    fontWeight: "900",
    fontSize: 18,
    letterSpacing: 0.9,
    paddingBottom: 10,
  },
  aboutHeader2: {
    color: "black",
    fontFamily: "Avenir-Book",
    fontWeight: "900",
    fontSize: 16,
    letterSpacing: 0.9,
    paddingVertical: 10,
  },
  avatar: {
    height: 90,
    width: 90,
    borderRadius: 100,
    marginRight: 10,
  },
  smallText: {
    color: "black",
    fontFamily: "Avenir-Book",
    fontSize: 15,
  },
  verticalHr: {
    marginTop: 20,
    marginBottom: 20,
    borderRightColor: Colors.verticalHrColor,
    borderRightWidth: StyleSheet.hairlineWidth,
  },
  sideBarBig: {
    marginTop: 40,
    marginHorizontal: 20,
    width: "30%",
    minWidth: 250,
  },
  sideBarSmall: {
    alignItems: "center",
    marginHorizontal: 10,
  },
  instructorRow: {
    flexDirection: "row",
    paddingBottom: 10,
  },
  button: {
    backgroundColor: "#16B4A4",
    borderRadius: 35,
    alignItems: "center",
    margin: 20,
    alignSelf: "center",
    width: 250,
  },
  buttonText: {
    color: "white",
    fontFamily: "Avenir-Book",
    margin: 10,
    fontWeight: 600,
  },
  downloadImage: {
    resizeMode: "contain",
    height: 100,
    width: 250,
    alignSelf: "center",
  },
});

export default Body;
