import React from "react";
import { View, Text, Image } from "react-native";
import { Colors } from "../styles";
import filledStar from "../assets/black-star-filled.png";
import emptyStar from "../assets/black-star-empty.png";

export const StarRatingIndicator = ({ value = 0, reviews = 0 }) => {
  return (
    <View>
      <View style={{ flexDirection: "row", marginBottom: 5 }}>
        {Array.from(Array(5).keys()).map((i) => (
          <Image
            key={`star${i}`}
            style={{
              resizeMode: "contain",
              height: 20,
              width: 20,
              margin: 2,
            }}
            source={value > i ? filledStar : emptyStar}
          />
        ))}
      </View>
      <Text
        style={{
          fontFamily: "Avenir-Book",
          fontSize: 12,
          fontWeight: "600",
          letterSpacing: 0.5,
          marginLeft: 3,
          marginBottom: 10,
          color: Colors.purple,
        }}
      >
        {reviews > 0
          ? reviews === 1
            ? "1 Review"
            : `${reviews} Reviews`
          : "No reviews yet"}
      </Text>
    </View>
  );
};
