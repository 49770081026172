import React from "react";
import { View, Image, Text, StyleSheet } from "react-native";
import { Colors } from "../styles";
import isInPersonImg from "../assets/inperson-label.png";
import isOnlineImg from "../assets/online-label.png";

const LessonSummaryBanner = ({ listing, isBigScreen }) => {
  const { rateType, approxTime, rate, locations, instructor } = listing;

  const approxTimeConversion = (str) => {
    const num = parseInt(str, 10);
    const hours = Math.floor(num / 60);
    const minutes = num % 60;

    const displayHours = hours > 0 ? hours + "h " : "";
    const displayMin = minutes > 0 ? minutes + "m" : "";
    return displayHours + displayMin;
  };

  const getCityAndStateFromLoc = (loc = "") => {
    const addressArray = loc.split(", ");
    let cityAndState = "Online";

    if (addressArray.length > 2) {
      cityAndState =
        addressArray[addressArray.length - 3] +
        ", " +
        addressArray[addressArray.length - 2];
    }
    return cityAndState;
  };

  const isOnline = locations.some((i) => i.type.includes("online"));

  const isInPerson = locations.some(
    (i) =>
      i.type.includes("publicSpace") ||
      i.type.includes("studentsHome") ||
      i.type.includes("myStudio")
  );

  console.log(JSON.stringify(listing, null, 2));
  let cityToDisplay;

  if (isInPerson) {
    //look for location with city and state
    const locationsWithAnAddress = locations.filter((locObj) => {
      return (
        locObj.type === "myStudio" ||
        locObj.type === "publicSpace" ||
        locObj.type === "studentsHome"
      );
    });
    const firstAddress = locationsWithAnAddress?.[0]?.location;
    if (firstAddress) {
      cityToDisplay = getCityAndStateFromLoc(firstAddress);
    } else {
      //set to be teacher's home city
      cityToDisplay = getCityAndStateFromLoc(instructor?.[0]?.homeCity);
    }
  } else {
    //is online only
    cityToDisplay = "Online";
  }

  return (
    <View
      style={[
        styles.banner,
        !isBigScreen && {
          flexDirection: "column",
          marginVertical: 10,
          // alignItems: "flex-start",
        },
      ]}
    >
      <Text style={styles.bannerText}>{cityToDisplay}</Text>

      {rateType === "fixed" ? (
        <Text style={styles.bannerText}>
          {approxTimeConversion(approxTime)} • ${rate}
        </Text>
      ) : (
        <Text style={styles.bannerText}>${rate} /hr</Text>
      )}
      <View style={isBigScreen && styles.lessonTypeContainer}>
        {isInPerson === true && (
          <Image
            resizeMode="contain"
            style={styles.lessonTypeImg}
            source={isInPersonImg}
          />
        )}
        {isOnline === true && (
          <Image
            resizeMode="contain"
            style={styles.lessonTypeImg}
            source={isOnlineImg}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  banner: {
    flexDirection: "row",
    // height: 50,
    width: "100%",
    backgroundColor: Colors.lightBlue,
    alignItems: "center",
  },
  bannerText: {
    color: Colors.purple,
    fontFamily: "Avenir-Book",
    fontSize: 18,
    letterSpacing: 0.9,
    paddingHorizontal: 20,
  },
  lessonTypeContainer: {
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: 50,
  },
  lessonTypeImg: {
    height: 55,
    width: 100,
    marginLeft: 10,
  },
});
export default LessonSummaryBanner;
