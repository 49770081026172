import React from "react";
import { View, Image, Text, StyleSheet } from "react-native";
import { Colors } from "../styles";
import { get, find } from "lodash";
import avatarDefault from "../assets/avatar.png";

const Header = ({ listing, isBigScreen }) => {
  const { title, instructor, images } = listing;
  const { avatarThumb } = instructor[0];

  // const isMobile = screenWidth < 501;

  return (
    <>
      {images.length > 0 ? (
        <Image
          source={get(find(images, "banner"), "thumbUrl", images[0].thumbUrl)}
          style={styles.banner}
        />
      ) : (
        <View style={styles.banner} />
      )}
      {!isBigScreen ? (
        <View
          style={{
            alignItems: "center",
            marginTop: -45,
          }}
        >
          <Image
            source={avatarThumb ? avatarThumb : avatarDefault}
            resizeMode="contain"
            style={[
              {
                height: 90,
                width: 90,
                borderRadius: 100,
              },
              isBigScreen && {
                position: "absolute",
                top: 150,
                left: 50,
              },
            ]}
          />
        </View>
      ) : (
        <Image
          source={avatarThumb ? avatarThumb : avatarDefault}
          resizeMode="contain"
          style={{
            height: 90,
            width: 90,
            borderRadius: 100,
            position: "absolute",
            top: 150,
            left: 50,
          }}
        />
      )}
      <Text
        style={[
          styles.listingTitle,
          isBigScreen
            ? { paddingTop: 50 }
            : { textAlign: "center", width: "100%" },
        ]}
      >
        {title}
      </Text>
    </>
  );
};

const styles = StyleSheet.create({
  banner: {
    height: 200,
    width: "100%",
    backgroundColor: Colors.purple,
  },
  listingTitle: {
    color: "black",
    fontFamily: "Avenir-Book",
    fontWeight: "900",
    fontSize: 22,
    letterSpacing: 0.9,
    paddingHorizontal: 20,
    // paddingTop: 50,
  },
});

export default Header;
