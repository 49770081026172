const timeConversion = (str) => {
  let [hour, minute] = str.split(":");
  if (parseInt(hour, 10) > 11) {
    if (parseInt(hour, 10) > 12) {
      hour = hour - 12;
    }
    return hour + ":" + minute + " PM";
  }
  return parseInt(hour, 10) + ":" + minute + " AM";
};

export default timeConversion;
