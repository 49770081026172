import React, { useEffect } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import { useParams } from "react-router-dom";
import { useApi } from "./hooks/useApi";
import { Colors } from "./styles";
import Header from "./components/Header";
import LessonSummaryBanner from "./components/LessonSummaryBanner";
import Body from "./components/Body";
import ErrorPage from "./Error";
import { useMediaQuery } from "react-responsive";
import getListingById from "./api/listing";
import getAllPassions from "./api/passions";

const Listing = () => {
  const { listingId } = useParams();

  // Variables and methods for API calls
  const {
    request: getListing,
    loading: loadingListing,
    data: listing,
    error: listingError,
    errorMessage,
  } = useApi(getListingById);

  const {
    request: getPassions,
    loading: loadingPassions,
    data: allPassions,
  } = useApi(getAllPassions);

  // Get passions once
  useEffect(() => {
    let isMounted = true;

    if (allPassions.length < 1 && isMounted) {
      getPassions().then(() => {
        if (!isMounted) return;
        // State update here is safe because we checked if the component is still mounted
      });
    }

    return () => {
      isMounted = false; // Cleanup to prevent state updates after unmounting
    };
  }, [getPassions, allPassions]);

  // Get listing every time listingId changes
  useEffect(() => {
    let isMounted = true;

    if (listingId && isMounted) {
      getListing(listingId).then(() => {
        if (!isMounted) return;
        // State update here is safe because we checked if the component is still mounted
      });
    }

    return () => {
      isMounted = false; // Cleanup to prevent state updates after unmounting
    };
  }, [listingId, getListing]);

  // Set meta data on index.html when listing is updated
  useEffect(() => {
    if (listing && listing.title) {
      // Set the title and description meta tags
      document.title = `${listing.title}`;

      const ogTitleElement = document.querySelector('meta[property="og:title"]');
      const descriptionElement = document.querySelector('meta[name="description"]');
      const ogDescriptionElement = document.querySelector('meta[property="og:description"]');
      
      if (ogTitleElement) {
        ogTitleElement.setAttribute("content", listing.title);
      }
      if (descriptionElement) {
        descriptionElement.setAttribute("content", listing.description);
      }
      if (ogDescriptionElement) {
        ogDescriptionElement.setAttribute("content", listing.description);
      }

      // Determine the image URL to use
      let imageUrl = null;

      if (listing.images && listing.images.length > 0) {
        imageUrl = listing.images[0].imageUrl || listing.images[0].url; // Use the imageUrl or url if available
      } else if (
        listing.instructor &&
        listing.instructor.length > 0 &&
        listing.instructor[0].avatar
      ) {
        imageUrl = listing.instructor[0].avatar; // Use the instructor's avatar if no listing image is available
      }

      // Set the image meta tags if an image URL was found
      if (imageUrl) {
        const ogImageElement = document.querySelector('meta[property="og:image"]');
        const ogImageSecureElement = document.querySelector('meta[property="og:image:secure_url"]');
        const twitterImageElement = document.querySelector('meta[name="twitter:image"]');

        if (ogImageElement) {
          ogImageElement.setAttribute("content", imageUrl);
        }
        if (ogImageSecureElement) {
          ogImageSecureElement.setAttribute("content", imageUrl);
        }
        if (twitterImageElement) {
          twitterImageElement.setAttribute("content", imageUrl);
        }
      }

      window.history.pushState(
        {},
        null,
        `/#/${listing.guid}/${encodeURIComponent(
          listing.title.split(" ").join("-")
        )}`
      );
    }
  }, [listing]);

  const isBigScreen = useMediaQuery({
    query: "(min-width: 501px)",
  });
  const isHugeScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  return (
    <View
      style={
        isBigScreen
          ? isHugeScreen
            ? styles.hugeScreen
            : styles.bigScreen
          : styles.smallScreen
      }
    >
      {loadingListing ? (
        <ActivityIndicator size="large" color={Colors.springGreen} />
      ) : listingError || listing === undefined || listing.length === 0 ? (
        <ErrorPage errorMessage={errorMessage} />
      ) : (
        <>
          <Header listing={listing} isBigScreen={isBigScreen} />
          <LessonSummaryBanner listing={listing} isBigScreen={isBigScreen} />
          <Body
            listing={listing}
            isBigScreen={isBigScreen}
            allPassions={allPassions}
            loadingPassions={loadingPassions}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  bigScreen: { flex: 1, minWidth: 795 },
  smallScreen: {
    flex: 1,
  },
  hugeScreen: { flex: 1, marginHorizontal: "14%" },
});

export default Listing;
