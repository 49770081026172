import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import ErrorPage from "./Error";
import Listing from "./Listing";

const App = () => {
  return (
    <Router basename="/">
      <Switch>
        <Route path="/:listingId" component={Listing} />
        <Route path="/" component={ErrorPage} />
      </Switch>
    </Router>
  );
};

export default App;
