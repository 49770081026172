import React, { useEffect } from "react";
import { View, StyleSheet, Text, Dimensions } from "react-native";
let ScreenHeight = Dimensions.get("window").height;

const ErrorPage = ({ errorMessage }) => {
  useEffect(() => {
    if (errorMessage) {
      console.log(errorMessage);
    }
    const timer = setTimeout(() => {
      window.location.href = "https://getskillin.com"; // Redirect to the external URL
    }, 3000); // Redirect after 3 seconds
  
    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [errorMessage]);
  return (
    <View style={styles.screen}>
      <Text>Sorry, this page was not found.</Text>
      <p>Redirecting you to getskillin.com.</p>
    </View>
  );
};
const styles = StyleSheet.create({
  screen: {
    justifyContent: "center",
    alignItems: "center",
    // minWidth: 795,
    height: ScreenHeight,
  },
});
export default ErrorPage;
