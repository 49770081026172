import { find } from "lodash";

//takes all passions array, teacher's passions array, and listing passionId
export const getLevel = (allPassions, teachersPassions, listingPassionId) => {
  const parentPassionId = find(allPassions, [
    "guid",
    listingPassionId,
  ])?.parentPassionId;

  const instructorLevel = find(teachersPassions, [
    "passionId",
    parentPassionId,
  ])?.level;

  return instructorLevel || "Hobbyist";
};
