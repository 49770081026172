const dayConversion = (str) => {
  if (str === "0") {
    return "Sundays";
  }
  if (str === "1") {
    return "Mondays";
  }
  if (str === "2") {
    return "Tuesdays";
  }
  if (str === "3") {
    return "Wednesdays";
  }
  if (str === "4") {
    return "Thursdays";
  }
  if (str === "5") {
    return "Fridays";
  }
  if (str === "6") {
    return "Saturdays";
  }
};

export default dayConversion;
